import {Box, Button} from '@material-ui/core';
import {Field, FieldInputProps, FieldProps, Form, Formik} from 'formik';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {useHistory, useLocation, useParams} from 'react-router';
import {StudioCheckbox} from '../../base-components/StudioCheckbox';
import {DropArea} from '../../base-components/StudioDropArea';
import {StudioSelect} from '../../base-components/StudioSelect';
import {ACCEPTED_FILE_TYPES} from '../TestDeployView/DeployConfigPanel';
import {FileExplorer} from '../TestDeployView/FileExplorer';
import * as Yup from 'yup';
import {useSelector} from 'react-redux';
import {RootState, useAppDispatch} from '../../store';
import cn from 'classnames';
import {LoadingSkeleton} from './LoadingSkeleton';
import {ReturnToPreviousPageButton} from '../../base-components/StudioButton/ReturnToPreviousPageButton';
import {usePostProcessorList} from '../../api/postprocessor/GetPostProcessorList';
import {RUNTIME_MODE_OPTIONS, RuntimeModeType} from '../TestDeployView/useDeploy';
import {OpenVXRuntimeContainer} from './OpenVXRuntimeContainer';
import {
  updatePermissions,
  UPDATE_PERMISSIONS_URL,
} from '../../api/postprocessor/UpdatePermissions';
import {
  assignPostProcessor,
  ASSIGN_POST_PROCESSOR_URL,
} from '../../api/postprocessor/AssignPostProcessor';
import {ONNXRuntimeContainer} from '../ONNXRuntimeContainer/ONNXRuntimeContainer';
import {toast} from '../../base-components/StudioToast';
import {Link} from 'react-router-dom';
import {
  UpdateParameterRequest,
  updateParameters,
} from '../../api/postprocessor/UpdateParameters';
import {
  DeploymentPostProcessingParameters,
  getDeployParamsKey,
  PreProcessingParameters,
} from '../TestDeployView/DeployConfigPanel/TestDeployParameters';
import './InferenceApp.scss';
import {useSWRConfig} from 'swr';
import {Parameter} from '../../types/model/framework/Parameter';
import {Alert} from '@material-ui/lab';
import {InferenceApplicationInfo} from '../../types/deployment/InferenceApplicationsResponse';
import {InferenceApplicationsRequest} from '../../types/deployment/InferenceApplicationsRequest';
import {fetchInferenceApps} from '../../store/prodDeployments/dashboard';
import {Project} from '../../types/project/Project';
import {projectFetch} from '../../store/project';
import {ApplicationProcessing} from '../../types/project/Application';
import {ModelPurpose} from '../../types/model/ModelPurpose';

export type InferenceAppExecution =
  | {
      type: 'OPENVX';
      application: ApplicationProcessing;
      deploymentId?: string;
      images: File[];
    }
  | {
      type: 'ONNX_RUNTIME_WEB';
      application: ApplicationProcessing;
      images: File[];
      runtimeMode: RuntimeModeType;
      configuredParams: Parameter[];
      id: string;
    };

type CreateInferenceAppForm = {
  exposeAPI: boolean;
  exposeInferencePage: boolean;
  postProcessorId: string | null | undefined;
  images: File[];
  runtimeMode: RuntimeModeType;
  postProcessorParams: Array<UpdateParameterRequest>;
  preProcessorParams: Array<UpdateParameterRequest>;
};

const ValidationSchema = Yup.object().shape(
  {
    exposeInferencePage: Yup.bool()
      .required()
      .oneOf([true]),
    postProcessorId: Yup.string().required(),
    images: Yup.array().min(1),
  },
  [['exposeAPI', 'exposeInferencePage']]
);

export const CreateInferenceAppPage = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const {mutate} = useSWRConfig();
  const {projectId, applicationId} = useParams<{
    projectId: string;
    applicationId: string;
    deploymentId: string | undefined;
  }>();
  const intl = useIntl();
  const location = useLocation();
  const search = useMemo(() => new URLSearchParams(location.search), [location]);
  const exposeAPI = search.get('exposeAPI') === 'true';
  const exposeInference = search.get('exposeInference') === 'true';
  const findModelPurpose = (): ModelPurpose | undefined => {
    if (inferenceApplication) {
      return inferenceApplication.modelPurpose;
    } else if (application) {
      return application.model.modelPurpose;
    }
  };
  const findApplication = (): ApplicationProcessing | undefined => {
    if (inferenceApplication) {
      if (inferenceApplication.ownerId === userId) {
        if (application) {
          modelInputShapeRef.current = application.model.frameworkParameters.inputShape;
          return application;
        }
      } else {
        modelInputShapeRef.current = inferenceApplication.modelInputShape;
        return inferenceApplication;
      }
    } else if (application) {
      modelInputShapeRef.current = application.model.frameworkParameters.inputShape;
      return application;
    }
  };
  const [inProgress, setInProgress] = useState(false);
  const modelInputShapeRef = useRef<number[]>();
  const [executionContext, setExecutionContext] = useState<InferenceAppExecution>();
  const userId = useSelector((state: RootState) => state.user.userId);
  const groupId = useSelector((state: RootState) => state.user.groupId);
  const inferenceApps: Array<InferenceApplicationInfo> = useSelector(
    (state: RootState) => state.deploymentDashboard.inferenceApps.data
  );
  const inferenceApplication = inferenceApps.find(app => app.id === applicationId);
  const projectData: Project = useSelector((state: RootState) => state.project.data);
  const application = Object.values(projectData.applications).find(
    application => application.id === applicationId
  );
  const app: ApplicationProcessing | undefined = findApplication();
  const existingPostProcessorId = app?.postProcessors?.find(
    pp => pp.executionContainer === 'BROWSER' && pp.postProcessorType === 'APPLICATION'
  )?.id;
  const appPreviouslyPublished =
    Boolean(app?.exposeAPI) || Boolean(app?.exposeInferencePage);

  const {data: ppListData} = usePostProcessorList({projectId});
  const projectPostProcessors = ppListData
    ? ppListData.existingPostProcessors.filter(
        pp =>
          pp.executionContainer === 'BROWSER' && pp.postProcessorType === 'APPLICATION'
      )
    : [];
  const projectPostProcessorOptions =
    projectPostProcessors.reduce((acc, curr) => ({...acc, [curr.id]: curr.name}), {}) ||
    {};

  const modelPurpose: ModelPurpose | undefined = findModelPurpose();

  const enableDefaultPostProcessor =
    app?.runtimeType === 'OPENVX' && modelPurpose === 'Classification';

  const hasPostProcessors = projectPostProcessors.length > 0;

  useEffect(() => {
    if (inferenceApps.length === 0 || inferenceApplication?.ownerId === userId) {
      dispatch(projectFetch(projectId, false, false));
    }
  }, [projectId, dispatch, inferenceApplication?.ownerId, userId, inferenceApps.length]);

  useEffect(() => {
    if (applicationId && groupId) {
      const requestBody: InferenceApplicationsRequest = {
        filterOutNonInferenceApps: true,
        firstPage: 0,
        pageSize: 100,
        paginated: false,
        orderDefinitions: [],
        params: {},
        applicationId: applicationId,
        groupId,
      };

      dispatch(fetchInferenceApps(requestBody));
    }
  }, [projectId, applicationId, dispatch, groupId]);

  const update = async (values: Partial<CreateInferenceAppForm>, updatePerm: boolean) => {
    let updatedApplication = application;
    if (values.postProcessorId) {
      updatedApplication = await assignPostProcessor([
        ASSIGN_POST_PROCESSOR_URL,
        {projectId, applicationId, postProcessorId: values.postProcessorId},
      ]);
    }
    if (values.postProcessorParams?.length || values.preProcessorParams?.length) {
      const {data} = await updateParameters({
        projectId,
        applicationId,
        parameters: [
          ...(values.postProcessorParams || []),
          ...(values.preProcessorParams || []),
        ],
      });
      updatedApplication = data.body;
    }
    if (updatePerm) {
      updatedApplication = await updatePermissions([
        UPDATE_PERMISSIONS_URL,
        {
          projectId,
          applicationId,
          exposeAPI: Boolean(values.exposeAPI),
          exposeInferencePage: Boolean(values.exposeInferencePage),
        },
      ]);
    }
    return updatedApplication;
  };

  const handlePublish = async (values: CreateInferenceAppForm) => {
    try {
      if (userOwnsSPA) {
        await update(values, true);
        history.push(`/project/${projectId}/inference/${applicationId}/details`);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleSubmit = async (values: CreateInferenceAppForm) => {
    try {
      if (inProgress) {
        return;
      }
      setInProgress(true);

      const updatedApplication = userOwnsSPA
        ? await update(values, false)
        : inferenceApplication;

      // assign appropriate fields from app into updatedApplication
      if (updatedApplication && updatedApplication.runtimeType === 'OPENVX') {
        setExecutionContext({
          type: 'OPENVX',
          images: [...values.images],
          application: updatedApplication,
        });
      } else if (
        updatedApplication &&
        updatedApplication.runtimeType === 'ONNX_RUNTIME_WEB'
      ) {
        const configuredParameters = (
          values.postProcessorParams?.map<Parameter>(param => ({
            name: param.name,
            value: param.value,
          })) || []
        ).concat(
          values.preProcessorParams?.map<Parameter>(param => ({
            name: param.name,
            value: param.value,
          })) || []
        );

        setExecutionContext({
          type: 'ONNX_RUNTIME_WEB',
          images: [...values.images],
          application: updatedApplication,
          runtimeMode: values.runtimeMode,
          id: Date.now().toString(),
          configuredParams: configuredParameters,
        });
      }
    } catch (e) {
      setInProgress(false);
      console.error(e);
    }
  };

  const handleImageAppend = (existing: File[], newFiles: File[]) => {
    const merged = [...existing];
    newFiles.forEach(newFile => {
      if (!merged.find(file => file.name === newFile.name)) {
        merged.push(newFile);
      }
    });
    return merged;
  };

  const handlePostProcessorChange = async (
    event: React.ChangeEvent<{
      value: unknown;
    }>,
    field: FieldInputProps<string | null | undefined>
  ) => {
    try {
      if (userOwnsSPA) {
        field.onChange(event);
        const newId = event.target.value as string;
        await assignPostProcessor([
          ASSIGN_POST_PROCESSOR_URL,
          {projectId, applicationId, postProcessorId: newId},
        ]);
        mutate(getDeployParamsKey(projectId, applicationId));
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onCheckboxChanged = (
    field: FieldInputProps<boolean>,
    event: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    var url = new URL(window.location.href);
    url.searchParams.set(id, String(!field.value));
    window.history.replaceState(window.history.state, '', url.toString());
    return field.onChange(event);
  };

  const userOwnsSPA: boolean = !!application || userId === inferenceApplication?.ownerId;

  return (
    <Formik<CreateInferenceAppForm>
      initialValues={{
        exposeAPI: Boolean(app?.exposeAPI || exposeAPI),
        exposeInferencePage: Boolean(app?.exposeInferencePage || exposeInference),
        postProcessorId: existingPostProcessorId,
        images: [],
        runtimeMode: 'webgl',
        postProcessorParams: [],
        preProcessorParams: [],
      }}
      enableReinitialize
      validationSchema={ValidationSchema}
      onSubmit={handleSubmit}
    >
      {({isValid, dirty, values}) => (
        <div className="inference-app">
          <div className="inference-app__body">
            <Form className="inference-app__panel">
              <div className="inference-app__menu">
                <FormattedMessage id="inferenceApp.title" tagName="h2" />
              </div>
              <div className="inference-app__fields">
                {app && (
                  <div>
                    <div className="inference-app__label">
                      <FormattedMessage id="inferenceApp.app" />
                    </div>
                    <div className="inference-app__name">{app.name}</div>
                  </div>
                )}
                <div>
                  <Field name="exposeAPI" type="checkbox">
                    {({field}: FieldProps<CreateInferenceAppForm['exposeAPI']>) => (
                      <StudioCheckbox
                        name="exposeAPI"
                        label={intl.formatMessage({id: 'inferenceApp.exposeAPI'})}
                        checked={field.value}
                        onChange={event => onCheckboxChanged(field, event, 'exposeAPI')}
                        disabled={
                          inProgress ||
                          application?.runtimeType === 'ONNX_RUNTIME_WEB' ||
                          !userOwnsSPA
                        }
                      />
                    )}
                  </Field>
                  <Field name="exposeInferencePage">
                    {({
                      field,
                    }: FieldProps<CreateInferenceAppForm['exposeInferencePage']>) => (
                      <StudioCheckbox
                        name="exposeInferencePage"
                        label={intl.formatMessage({
                          id: 'inferenceApp.exposeInferencePage',
                        })}
                        checked={field.value}
                        onChange={event =>
                          onCheckboxChanged(field, event, 'exposeInference')
                        }
                        disabled={inProgress || !userOwnsSPA}
                      />
                    )}
                  </Field>
                </div>
                {values.exposeInferencePage && (
                  <>
                    <Field name="postProcessorId">
                      {({
                        field,
                      }: FieldProps<CreateInferenceAppForm['postProcessorId']>) => (
                        <div>
                          {hasPostProcessors && userOwnsSPA && (
                            <StudioSelect
                              label={intl.formatMessage({
                                id: 'inferenceApp.postProcessor',
                              })}
                              options={projectPostProcessorOptions}
                              SelectProps={{
                                ...field,
                                value: field.value || '',
                                onChange: e => {
                                  handlePostProcessorChange(e, field);
                                },
                              }}
                              fullWidth
                              disabled={inProgress}
                              placeholder="Select a post processor"
                              disablePlaceholder
                            />
                          )}
                          {enableDefaultPostProcessor &&
                            !hasPostProcessors &&
                            userOwnsSPA && (
                              <>
                                <Alert
                                  severity="warning"
                                  className="dplmt-config-field__alert"
                                >
                                  {intl.formatMessage({
                                    id: 'test.runtime.postprocessor.warning',
                                  })}
                                </Alert>
                                <Button color="primary">
                                  <Link
                                    to={`/project/${projectId}/postprocessor/new?applicationId=${applicationId}&appType=OPEN_VX_APP&useDefault&returnTo=spaCreate&assignToApplication&exposeAPI=${values.exposeAPI}&exposeInference=${values.exposeInferencePage}`}
                                  >
                                    Create default post processor
                                  </Link>
                                </Button>
                              </>
                            )}
                          {hasPostProcessors && userOwnsSPA && (
                            <Button color="primary" disabled={field.value == null}>
                              <Link
                                to={`/project/${projectId}/postprocessor/edit/${field.value}?applicationId=${applicationId}&returnTo=spaCreate&assignToApplication&exposeAPI=${values.exposeAPI}&exposeInference=${values.exposeInferencePage}`}
                              >
                                Edit / view post processor
                              </Link>
                            </Button>
                          )}
                          {userOwnsSPA && (
                            <Button color="primary">
                              <Link
                                to={`/project/${projectId}/postprocessor/new?applicationId=${applicationId}&returnTo=spaCreate&assignToApplication&exposeAPI=${values.exposeAPI}&exposeInference=${values.exposeInferencePage}`}
                              >
                                Create {enableDefaultPostProcessor ? 'custom' : 'new'}{' '}
                                post processor
                              </Link>
                            </Button>
                          )}
                        </div>
                      )}
                    </Field>
                    <div className="inference-app__params-container">
                      <Field name="preProcessorParams">
                        {({
                          form,
                        }: FieldProps<CreateInferenceAppForm['preProcessorParams']>) => (
                          <PreProcessingParameters
                            projectId={projectId}
                            applicationId={applicationId}
                            renderField={(field, param) => (
                              <div className="inference-app__param" key={param.name}>
                                {field}
                              </div>
                            )}
                            titleMessageId="test.form.preprocessing"
                            wrapperClassName="inference-app__accordion"
                            onValidityChange={isValid => {
                              if (!isValid) {
                                form.setFieldError(
                                  'preProcessingParams',
                                  'Invalid pre processing parameters'
                                );
                              }
                            }}
                            onChange={newParams =>
                              form.setFieldValue('preProcessingParams', newParams)
                            }
                            disabledForm={inProgress}
                          />
                        )}
                      </Field>
                      <Field name="postProcessorParams">
                        {({
                          form,
                        }: FieldProps<CreateInferenceAppForm['postProcessorParams']>) => (
                          <DeploymentPostProcessingParameters
                            projectId={projectId}
                            applicationId={applicationId}
                            renderField={(field, param) => (
                              <div className="inference-app__param" key={param.name}>
                                {field}
                              </div>
                            )}
                            titleMessageId="test.form.postprocessing"
                            wrapperClassName="inference-app__accordion"
                            onValidityChange={isValid => {
                              if (!isValid) {
                                form.setFieldError(
                                  'postProcessorParams',
                                  'Invalid post processing parameters'
                                );
                              }
                            }}
                            onChange={newParams =>
                              form.setFieldValue('postProcessorParams', newParams)
                            }
                            disabledForm={inProgress}
                          />
                        )}
                      </Field>
                    </div>
                    {application?.runtimeType === 'ONNX_RUNTIME_WEB' && (
                      <Field name="runtimeMode">
                        {({field}: FieldProps<CreateInferenceAppForm['runtimeMode']>) => (
                          <StudioSelect
                            required
                            id="runtime-mode"
                            fullWidth
                            disabled={inProgress}
                            label={intl.formatMessage({id: 'test.runtime.label'})}
                            options={RUNTIME_MODE_OPTIONS}
                            SelectProps={field}
                            tooltip={intl.formatMessage({id: 'test.runtime.tooltip'})}
                          />
                        )}
                      </Field>
                    )}
                    <Field name="images">
                      {({field, form}: FieldProps<CreateInferenceAppForm['images']>) => {
                        const setValue = (value: File[] | null) =>
                          form.setFieldValue('images', value);
                        return (
                          <div>
                            <div className="inference-app__label">
                              <FormattedMessage id="inferenceApp.images" />
                            </div>
                            <DropArea
                              onChange={files =>
                                setValue(handleImageAppend(values.images, files))
                              }
                              accept={ACCEPTED_FILE_TYPES.join(',')}
                              disabled={inProgress}
                            />
                            <Box>
                              <FileExplorer
                                files={field.value || []}
                                onDelete={(item, index) => {
                                  const updated = [...field.value];
                                  updated.splice(index, 1);
                                  setValue(updated);
                                }}
                                onClear={() => setValue([])}
                                size="small"
                                icon={inProgress ? 'check' : 'delete'}
                              />
                            </Box>
                          </div>
                        );
                      }}
                    </Field>
                  </>
                )}
              </div>
              <div className="inference-app__submit">
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disableElevation
                  disabled={!isValid || !dirty || inProgress}
                >
                  <FormattedMessage id="inferenceApp.test" />
                </Button>
              </div>
            </Form>
            <section className="inference-app__results">
              <div className="inference-app__menu">
                <ReturnToPreviousPageButton />
                {appPreviouslyPublished && (
                  <Button
                    className="inference-app__back"
                    variant="contained"
                    disableElevation
                    disabled={inProgress}
                    onClick={() =>
                      history.push(
                        `/project/${projectId}/inference/${applicationId}/details`
                      )
                    }
                  >
                    <FormattedMessage id="deployments.viewDetails" />
                  </Button>
                )}
                <Button
                  color="primary"
                  variant="contained"
                  disableElevation
                  disabled={
                    !userOwnsSPA ||
                    inProgress ||
                    (values.exposeInferencePage && values.postProcessorId == null) ||
                    (!values.exposeAPI && !values.exposeInferencePage)
                  }
                  onClick={() => handlePublish(values)}
                >
                  <FormattedMessage id="publish" />
                </Button>
              </div>
              <h5>
                {intl.formatMessage({
                  id: inProgress ? 'form.loading' : 'prodDeployment.inferenceResults',
                })}
              </h5>
              {inProgress && <LoadingSkeleton count={values.images.length} />}
              <div
                id="inference-results-element"
                className={cn(inProgress && 'inference-app--hidden')}
              ></div>
              {executionContext?.type === 'OPENVX' && (
                <OpenVXRuntimeContainer
                  projectId={projectId}
                  application={executionContext.application}
                  images={executionContext.images}
                  onError={() => setInProgress(false)}
                  onFinish={() => setInProgress(false)}
                />
              )}
              {modelInputShapeRef.current &&
                executionContext?.type === 'ONNX_RUNTIME_WEB' && (
                  <ONNXRuntimeContainer
                    key={executionContext.id}
                    type="IMAGES"
                    projectId={projectId}
                    application={executionContext.application}
                    runtimeMode={executionContext.runtimeMode}
                    configuredParams={executionContext.configuredParams}
                    inputShape={modelInputShapeRef.current}
                    files={executionContext.images}
                    onError={message => {
                      if (message) {
                        toast.error(message);
                      }
                      setInProgress(false);
                    }}
                    onFinish={() => setInProgress(false)}
                  />
                )}
            </section>
          </div>
        </div>
      )}
    </Formik>
  );
};
